$colorPrimary: #1E73BE;
$colorPrimaryDark : #07457C;
$colorPrimaryLight : #66A1D5;
$colorSecondary: rgb(255, 193, 7);//#c74e28;
$colorSecondaryLight: #FFD967;
$colorSecondaryDark: #C99700;

$mobilePoint: 992px;

:root {
  --bs-list-group-active-bg: $colorPrimary;
  --bs-list-group-active-border-color: $colorPrimary;
}

html, body{
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}
.body-wrapper{
  overflow: hidden;
}
a{
  color: $colorPrimary;
  &:hover{
    color: #000000//$colorSecondary;
  }
}
.btn-primary{
  background-color: $colorPrimary;
}
.btn-danger{
  background-color: $colorSecondary;
}

body.fixed-body{
  position: fixed;
  overflow: hidden;
  width: 100%;
}
body.scrollable{
  padding-top: 100px;
}


.ns-container {
  margin-bottom: 40px;
  .ns {
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 20px 0 15px 0;
    color: #666666;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;

      &::before {
        font-family: "Font Awesome 5 Pro";
        content: "\f105";
        margin: 0 4px;
        vertical-align: middle;
        line-height: 1;
      }

      &:first-child::before {
        content: '';
        margin: 0;
      }
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: #555555;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
        color: #000000;
      }
    }
  }
  @media (max-width: 992px) {
    padding: 5px 0;
    overflow: auto;
    white-space: nowrap;
    .ns{
      flex-wrap: nowrap;
    }
  }
}

.main-header{

  min-height: 100vh;
  background: url("img/bg.webp") center center no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 5%;

  &.main-header-inside{
    min-height: auto;
    background: rgba(6, 20, 43, 0.9);
    .main-header-icons,
    .main-header-body,
    .main-header-footer{
      display: none;
    }
    .main-header-topbar{
      padding: 5px 0;
      .contacts {
        .contact a {
          font-size: 16px;
        }
      }
    }
  }

  &:after{
    content: '';
    position: absolute;
    width: 55%;
    height: 100%;
    min-height: 100%;
    top: 0;
    right: 0;
    background: url("img/slide2.webp") center 1vh no-repeat;
    background-size: cover;
    z-index: 0;

    @media (max-width: $mobilePoint) {
      display: none;
    }
  }


  .main-header-topbar{
    display: flex;
    flex-direction: row;
    padding: 5vh 0 1vh 0;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    .logo{
      a{
        img{

        }
      }
      flex-grow: 1;
    }
    .menu{
      padding-left: 2vw;
      display: block;
      font-size: 16px;
      line-height: 2;
      &>ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: block;
        text-align: right;
        &>li{
          padding: 0;
          margin: 0 0.8vw;
          display: inline-block;
          position: relative;
          &>a{
            color: #ffffff;
            text-decoration: none;
            &:hover{
              color: #c0c0c0;
            }
          }
          &>ul{
            display: none;
            position: absolute;
            top: 98%;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
            padding: 20px 0 0 0;
            text-align: left;
            list-style: none;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            z-index: 2;
            &>li{
              background: #ffffff;
              position: relative;
              &>a{
                display: block;
                padding: 7px 20px;
                color: #444444;
                white-space: nowrap;
                text-decoration: none;
                &:hover{
                  color: $colorPrimary;
                }
              }
            }
            &:after{
              content: "";
              display: inline-block;
              width: 10px;
              height: 10px;
              border: 5px solid;
              box-sizing: border-box;
              border-color: transparent transparent #ffffff transparent;
              position: absolute;
              top: 10px;
              left: 50%;
              margin-left: -5px;
            }
          }
          &:hover{
            &>ul{
              display: block;
            }
          }
        }
        &>li.with-sub{
          &>a {
            &:after{
              content: '\f0d7';
              font-family: "Font Awesome 5 Pro";
              font-weight: 900;
              margin-left: 8px;
              font-size: 12px;
              color: #a0a0a0;
            }
          }
        }
      }
    }
    .contacts{
      padding: 20px 0 0 2vw;
      .contact{
        a{
          color: #ffffff;
          text-decoration: none;
          font-weight: bold;
          font-size: 22px;
          white-space: nowrap;
        }
      }
      .link{
        text-decoration: none;
        span{
          color: #ffffff;
          text-decoration: none;
          font-size: 14px;
          white-space: nowrap;
          border-bottom: 1px dashed #aaaaaa;
          cursor: pointer;
          opacity: 0.7;
          &:hover{
            opacity: 1;
          }
        }
      }
    }
    .actions{
      display: flex;
      padding-left: 20px;
      .btn{
        color: #ffffff;
        border-color: rgba(255, 255, 255, 0.5);
        white-space: nowrap;
        i{
          margin-right: 7px;
        }
        &:hover{
          color: #000;
          background: #fff;
          border-color: #fff;
        }
      }
    }

    @media (max-width: $mobilePoint) {
      .menu,
      .contacts,
      .actions{
        display: none;
      }
      .logo{
        text-align: center;
      }
    }

    &.sticky-topbar{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 4px 5%;
      background: rgba(6, 20, 43, 0.9);
      backdrop-filter: blur(5px);
      box-sizing: border-box;
      box-shadow: 0 0 50px rgba(0,0,0,0.3);
      .contacts{
        .contact{
          a{
            font-size: 16px;
          }
        }
      }
    }
  }
  $iconsRadius : 50;
  .main-header-icons{
    display: flex;
    justify-content: right;
    position: relative;
    padding: 2vh 0;
    z-index: 1;
    @media (max-width: $mobilePoint) {
      display: none;
    }
    .icon{
      display: inline-block;
      width: $iconsRadius + px;
      height: $iconsRadius + px;
      line-height: $iconsRadius + px;
      border: 1px solid #ffffff;
      border-radius: $iconsRadius/2 + px;
      text-align: center;
      margin-left: 15px;
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;
      transition: 0.3s;
      &:hover{
        background: #ffffff;
        color: #000000;
      }
    }

    .search-block{
      flex-grow: 1;
      transition: 0.5s;
      .search-input{
        height: $iconsRadius + px;
        border-radius: $iconsRadius/2 + px;
        border: 1px solid #ffffff;
        position: relative;
        box-shadow: 0 0 20px $colorPrimary;
        input{
          border-radius: $iconsRadius/2 + px;
          height: $iconsRadius + px;
          line-height: $iconsRadius + px;
          padding: 0 $iconsRadius/2 + px;
          width: 100%;
          box-sizing: border-box;
          border: 0;
          background: transparent;
          outline: none;
          color: #ffffff;
          font-size: 18px;
          transition: 0.5s;
          &:focus{
            background: rgba(255, 255, 255, 0.2);
            color: #ffffff;
          }
        }
        .input-buttons {
          position: absolute;
          right: 0;
          top: 0;
          .input-button {
            display: inline-block;
            width: $iconsRadius + px;
            height: $iconsRadius + px;
            line-height: $iconsRadius + px;
            text-align: center;
            font-size: 18px;
            color: #ffffff;
            cursor: pointer;
            border: 0;
            background: transparent;
          }
        }
      }
    }
  }
  .main-header-body{
    padding-right: 50%;
    color: #ffffff;
    .text1{
      color: #d0d0d0;
      font-size: 1.6vw;
      padding-bottom: 1.2vh;
      font-weight: bold;
    }
    .text2{
      font-size: 4vw;
      font-weight: 900;
      line-height: 1;
    }
    .text3{
      padding-top: 1.2vh;
      font-size: 1.5vw;
      font-weight: 400;
      line-height: 1.8;
      color: #d0d0d0;
      b{
        color: $colorSecondary;
      }
    }
    .button{
      padding-top: 5vh;
      .main-header-button{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        background: $colorSecondary;
        color: #000000;
        height: 60px;
        line-height: 60px;
        border-radius: 6px;
        padding: 0 30px;
        text-decoration: none;
        font-size: 1.2vw;
        transition: box-shadow 0.3s, transform 0.3s;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(-45deg, transparent, rgba(255, 255, 255, 0.8), transparent);
          transition: left 0.7s;
        }
        &:hover{
          transform: translateY(-4px);
          box-shadow: 0 4px 12px rgba(0,0,0,0.2), 0 16px 20px rgba(0,0,0,0.2);
          //color: #FFF;
          &:before{
            left: 100%;
          }
        }
      }
    }
    @media (max-width: $mobilePoint) {
      padding-right: 0;
      padding-top: 30px;
      .text1{
        font-size: 4vw;
        padding-bottom: 1.2vh;
      }
      .text2{
        font-size: 9vw;
      }
      .text3{
        padding-top: 1.2vh;
        font-size: 4vw;
        font-weight: 400;
      }
      .button{
        padding-top: 5vh;
        .main-header-button{
          width: 100%;
          text-align: center;
          font-size: 18px;
        }
      }
    }
  }
  .main-header-footer{
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    .header-scroll{
      display: inline-block;
      text-align: center;
      font-size: 40px;
      color: #ffffff;
      cursor: pointer;
      i{
        display: block;
      }
      .a-pulse {
        animation: pulse 1s linear infinite;
      }
      @keyframes pulse {
        0% {
          transform: scale(0.5);
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          transform: scale(1.2);
          opacity: 0;
        }
      }
      //&:after{
      //  content: '\f175';
      //  font-family: "Font Awesome 5 Pro";
      //  font-weight: 300;
      //  margin-left: 8px;
      //}
    }
  }

}

.main-menu{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  height: 100%;
  width: 100%;
  background: #ffffff;
  z-index: 100;
  .menu-toggle{
    position: absolute;
    top: calc(5vh + 30px);
    right: 5%;
    z-index: 3;
    span{
      background: #000;
    }
  }
  nav{
    display: block;
    background: #06142B;
    width: 40%;
    height: 100%;
    position: relative;
    padding-top: 5vh;
    z-index: 2;
    .h{
      font-size: 28px;
      font-weight: bold;
      padding: 0 0 0 5%;
      line-height: 70px;
      a{
        color: #ffffff;
        text-decoration: none;
        margin-left: 20px;
      }
    }
    &>ul{
      margin: 0;
      padding: 0 0 20px 5%;
      box-sizing: border-box;
      width: 100%;
      list-style: none;
      &>li{
        &>a{
          color: #ffffff;
          display: block;
          padding: 20px 25px 20px 20px;
          text-decoration: none;
          box-sizing: border-box;
          position: relative;
          font-size: 18px;
          &:hover{
            text-decoration: underline;
          }
          &:after{
            content: "\f054";
            font-family: "Font Awesome 5 Pro";
            display: inline-block;
            position: absolute;
            right: 15px;
            top: 20px;
            font-weight: 900;
          }
        }
        &.active{
          background: #ffffff;
          border-radius: 6px 0 0 6px;
          &>a {
            color: #000000;
          }
        }
        &>ul{
          display: none;
          position: absolute;
          left: 100%;
          top: 0;
          width: 130%;
          padding: 70px 0 20px 40px;
          list-style: none;
          &>li{
            margin: 0;
            padding: 20px 0;
            &>a{
              color: $colorPrimary;
              text-decoration: none;
              &:hover{
                color: $colorSecondary;
              }
            }
            &>ul{
              list-style: none;
              padding: 0;
              margin: 0;
              &>li{
                padding: 0;
                margin: 20px 0;
                a{
                  color: #888888;
                  text-decoration: none;
                  &:before{
                    content: "—";
                    margin-right: 6px;
                  }
                  &:hover{
                    color: #000000;
                  }
                }
              }
            }
          }
        }
        &.active{
          &>ul {
            display: block;
          }
        }
      }
    }
  }
}


.big-bars {
  display: inline-block;
  width: 60px;
  height: 40px;
  position: relative;
 // margin: 30px auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}
.big-bars span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}
.big-bars span:nth-child(1) {
  top: 0;
  transform-origin: left center;
}
.big-bars span:nth-child(2) {
  top: 18px;
  transform-origin: left center;
}
.big-bars span:nth-child(3) {
  top: 36px;
  transform-origin: left center;
}
.big-bars.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}
.big-bars.open span:nth-child(2) {
  width: 0;
  opacity: 0;
}
.big-bars.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}

.fancy-dialog{
  display: none;
  max-width: 80%;
  &.fancy-dialog-md{
    width: 992px;
  }
  &.fancy-dialog-sm{
    width: 400px;
  }
  .fancy-header{
    color: #000000;
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 30px;
  }
  &.fancy-dialog-no-padding{
    padding: 0;
  }
  .fancy-body{
    padding: 2vw 3vw;
  }
}
.order-dialog{
  background-color: #ffffff;
  .order-body{
    display: flex;
    flex-direction: column;

    h3{
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 40px;
    }
    .order-text{
      font-size: 18px;
      color: rgba(255, 255, 255, 0.7);
      strong{
        color: #fff;
      }
    }
    .order-icon{
      overflow: hidden;
      background: url("img/calc.webp") center 0 no-repeat;
      flex-grow: 1;
      margin-bottom: -2vw;
      margin-right: -3vw;
      margin-left: -3vw;
    }
  }
}
.col-blue{
  background: $colorPrimary;
  background: -moz-linear-gradient(45deg,  #1e73be 0%, #091621 100%);
  background: -webkit-linear-gradient(45deg,  #1e73be 0%,#091621 100%);
  background: linear-gradient(45deg,  #1e73be 0%,#091621 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e73be', endColorstr='#091621',GradientType=1 );

  color: #ffffff;
  height: 100%;

}
.list-block-wrapper{

  background: url("img/lines.webp") center center no-repeat;
  background-size: cover;
  padding: 50px 0 100px 0;
  position: relative;

  &:after{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;

    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );

  }

}
.list-block{
  .list-block-content{
    display: flex;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 80px rgba(0,0,0,0.20);
    border-radius: 10px;
    
    @media (max-width: $mobilePoint) {
      display: none;
    }
  }
  .h{
    font-size: 58px;
    font-weight: 900;
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
  }

  .list-block-aside{
    width: 30%;
    padding: 2vw 0;
    background: #ffffff;
    border-radius: 10px 0 0 10px;
    .item{
      display: block;
      cursor: pointer;
      padding: 20px 30px;
      background: #ffffff;
      font-weight: bold;
      position: relative;
      z-index: 1;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      &:first-child{
        border-top-color: transparent;
      }
      &:hover{
        color: $colorPrimary;
        text-decoration: underline;
      }
      &:after{
        content: '';
        position: absolute;
        left: 13px;
        top: 50%;
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-top: -2px;
        background: $colorSecondary;
      }
      &.active{
        background: $colorPrimaryDark;
        background: -moz-linear-gradient(left,  #1d73be 0%, $colorPrimaryDark 100%);
        background: -webkit-linear-gradient(left,  #1d73be 0%,$colorPrimaryDark 100%);
        background: linear-gradient(to right,  #1d73be 0%,$colorPrimaryDark 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d73be', endColorstr='$colorPrimaryDark',GradientType=1 );
        color: #ffffff;
        //margin: 0 0 0 -10px;
        //padding: 20px 30px 20px 20px;
        z-index: 2;
        &:hover{
          color: #ffffff;
          cursor: default;
          text-decoration: none;
        }
        &:after{
          background: #ffffff88;
          //left: 20px;
        }
        &:before{
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-width: 10px;
          border-color: transparent;
          border-right-color: $colorSecondary;
          border-style: solid;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -10px;
        }
      }

    }
  }
  .list-block-body{
    width: 70%;
    padding: 2vw 0 0 0;
    position: relative;
    background: $colorSecondary; //$colorPrimaryDark url("img/catbg.webp") 100% 0 no-repeat;
    background: -moz-linear-gradient(-45deg,  $colorSecondaryLight 0%, $colorSecondaryLight 72%, rgba(244,165,7,1) 100%);
    background: -webkit-linear-gradient(-45deg,  $colorSecondaryLight 0%,$colorSecondaryLight 72%,rgba(244,165,7,1) 100%);
    background: url("img/noise.png"), linear-gradient(135deg,  $colorSecondaryLight 0%,$colorSecondaryLight 72%,rgba(244,165,7,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc107', endColorstr='#f4a507',GradientType=1 );

    background-blend-mode: exclusion;

    //background-size: cover;
    border-radius: 0 10px 10px 0;

    .item{
      display: none;
      opacity: 0;
      transform: translateY(-30px);
      transition: 0.2s;
      &.active{
        visibility: visible;
        display: block;
        opacity: 1;
        transform: translateY(0);
      }
      .img{
        position: absolute;
        top: 0;
        right: -20%;
        //margin-top: -40px;
        width: 500px;
        height: 100%;
        //transform: translateY(-30%);

        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;

        @media (max-width: 1400px) {
          width: 400px;
          //height: 400px;
          right: -15%;
          //top: -40px;
        }

        @media (max-width: 1200px) {
          width: 350px;
          //height: 350px;
          right: -25%;
          //top: -30px;
        }
      }
      .links{
        display: flex;
        padding: 2vw 3vw;
        //background: $colorSecondary;
        justify-content: space-between;

        .list{
          width: 100%;//48%;
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
              padding: 0 0 0 25px;
              margin: 0 0 15px 0;
              position: relative;
              &:before{
                content: "\25cf";
                color: rgba(255, 255, 255, 0.2);
                position: absolute;
                top: 0;
                left: 0;
              }
              a{
                text-decoration: none;
                color: #ffffff;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                &:hover{
                  //color: $colorSecondary;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                }
              }
            }
          }
        }
      }
      .text{
        color:#000000;// rgba(255, 255, 255, 0.7);
        padding-left: 3vw;
        width: 70%;
        line-height: 20px;
        //height: 340px;
        p{
          margin-bottom: 20px;
        }
      }
      .link{
        padding: 30px 0 50px 3vw;
      }
      .more{
        margin-left: 3vw;
        color: $colorPrimary;
        display: none;
      }
    }
  }
  
  @media (max-width: $mobilePoint) {
    .h{
      font-size: 36px;
    }
    .list-block-content {
      flex-direction: column;
    }
    .list-block-aside{
      width: 100%;
    }
  }

  .list-block-mobile{

    .items{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        padding: 15px;
        .pic{
          display: block;
          text-align: center;
          padding-right: 20px;
          img{
            width: 100%;
          }
        }
        .name{
          text-align: center;
        }
      }
    }

    @media (min-width: $mobilePoint) {
      display: none;
    }
  }
}
.main-catalog-header{
  padding: 50px 0;
  position: relative;
  font-weight: normal;
  color: #666666;
  &:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #aaa url("img/bg-el.webp") center no-repeat;
    background-size: cover;
    opacity: 0.1;
    z-index: 1;
  }
  .main-catalog-header-row{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .item{
      width: 30%;
      padding: 30px;
      background: #ffffff;
      box-shadow: 0 0 20px rgba(0,0,0,0.1);
      z-index: 2;
      position: relative;
      i{
        display: inline-block;
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 36px;
        line-height: 40px;
        border-radius: 50%;
        font-weight: 900;
        background: $colorSecondary;
        color: #ffffff;
        margin-bottom: 10px;
        font-family: serif;
      }
    }
    @media (max-width: $mobilePoint) {
      flex-direction: column;
      .item{
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
}


.advantages{
  //background: $colorPrimaryLight url("img/av-bg.png") fixed;
  //background-blend-mode: lighten;
  padding: 50px 0;
  .advantages-head{
    text-align: center;
    color: #000000;
    h3{
      font-weight: 900;
      font-size: 58px;
    }
    h5{
      font-size: 32px;
      font-weight: 100;
    }
  }
  .advantages-body{
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .items{
      width: 48%;
      counter-reset: section;
      .item{
        background: #ffffff;
        border-radius: 15px;
        padding: 30px 30px 30px 64px;
        margin-bottom: 15px;
        border: 1px solid #e5e5e5;
        position: relative;
        &:after{
          display: inline-block;
          width: 28px;
          height: 28px;
          line-height: 28px;
          counter-increment: section;
          content: counter(section);
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          position: absolute;
          top: 30px;
          left: 19px;
          color: #000;
          border-radius: 50%;
          border: 1px solid #aaa;
        }
        .item-head{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .icon{
            display: none;
            font-size: 50px;
            color: $colorPrimaryDark;
            margin: 0 0 0 30px;
          }
          .title{
            font-weight: bold;
            font-size: 20px;
            color: #000000;
          }
          &:after{
            content: '\f078';
            font-family: "Font Awesome 5 Pro";
            transition: 0.5s;
          }
          &:hover{
            cursor: pointer;
            .title, &:after{
              color: $colorPrimary;
            }
          }
        }
        .item-body{
          display: none;
          padding-top: 20px;
          .text {
            color: #666666;
            p{
              margin-bottom: 20px;
            }
          }
        }
        &.opened{
          box-shadow: 0 0 50px rgba(0,0,0,0.1);
          border-color: transparent;
          .item-body{
            display: block;
          }
          &:after{
            color: #ffffff;
            background: $colorPrimary;
            border: 2px solid $colorSecondary;
          }
          .item-head{
            .title{
              &:hover {
                color: #000000;
              }
            }
            &:after{
              //content: '\f077';
              transform: rotate(180deg);
              color: $colorPrimary;
            }
          }
        }

      }
    }
    .description{
      width: 48%;
      .text{
        font-size: 24px;
        font-weight: 300;
        color: #000;
      }
    }
  }

  @media (max-width: $mobilePoint) {
    .advantages-head {
      h3 {
        font-size: 36px;
      }

      h5 {
        font-size: 28px;
      }
    }
    .advantages-body{
      flex-direction: column;
      .items{
        width: 100%;
        order: 2;
      }
      .description{
        width: 100%;
        .text{
          font-size: 18px;
        }
      }
    }
  }
}

.all-collapsed{
  .text {
    overflow: hidden;
  }
  .more{
    color: $colorPrimaryLight;
    border-bottom: 1px dashed $colorPrimaryLight;
    cursor: pointer;
    &:before{
      content: 'показать всё';
    }
    &:after{
      content: '\f0d7';
      font-family: "Font Awesome 5 Pro";
      margin-left: 4px;
      font-size: 14px;
      font-weight: bold;
    }

  }
  &.opened{
    .text {
      height: auto!important;
      overflow: visible;
    }
    .more {
      &:before{
        content: 'скрыть';
      }
      &:after {
        content: '\f0d8';
      }
    }
  }
}

.prim{
  position: relative;
  overflow: hidden;
  &>h3{
    font-size: 58px;
    font-weight: 900;
    color: #ffffff;
    position: absolute;
    top: 160px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 3;
    @media (max-width: $mobilePoint) {
      font-size: 42px;
    }
  }
  //&:before{
  //  content: '';
  //  display: block;
  //  position: absolute;
  //  top: -1px;
  //  left: 0;
  //  width: 100%;
  //  height: 10vw;
  //  z-index: 5;
  //  background: url("img/kr-t.png") center 0 no-repeat;
  //  background-size: cover;
  //}
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: -5vw;
    left: 0;
    width: 100%;
    height: 10vw;
    z-index: 5;
    background: #ffffff;
    transform: skewY(-3deg);
  }
  //&:after{
  //  content: '';
  //  display: block;
  //  position: absolute;
  //  bottom: -1px;
  //  left: 0;
  //  width: 100%;
  //  height: 10vw;
  //  z-index: 5;
  //  background: url("img/kr-b.png") center 100% no-repeat;
  //  background-size: cover;
  //}
  &:after{
    content: '';
    display: block;
    position: absolute;
    bottom: -5vw;
    left: 0;
    width: 100%;
    height: 10vw;
    z-index: 5;
    background: #ffffff;
    transform: skewY(-3deg);
  }
  .items{
    .item{
      background-size: cover;
      position: relative;
      padding: 280px 0 10vw 0;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;

        background: -moz-linear-gradient(45deg,  rgba(7,69,124,0.4) 0%, rgba(2,9,38,0.74) 40%, rgba(2,9,38,0.95) 64%, rgba(2,9,38,0.95) 100%);
        background: -webkit-linear-gradient(45deg,  rgba(7,69,124,0.4) 0%,rgba(2,9,38,0.74) 40%,rgba(2,9,38,0.95) 64%,rgba(2,9,38,0.95) 100%);
        background: linear-gradient(45deg,  rgba(7,69,124,0.4) 0%,rgba(2,9,38,0.74) 40%,rgba(2,9,38,0.95) 64%,rgba(2,9,38,0.95) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6607457c', endColorstr='#f2020926',GradientType=1 );

        backdrop-filter: blur(5px);

        z-index: 1;
      }
      .item-body{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        .head{
          position: relative;
          z-index: 2;
          width: 40%;
          .h{
            color: #ffffff;
            font-size: 36px;
            margin-bottom: 30px;
          }
          .pics {
            position: relative;
            img {
              width: 50%;
              border: 1vw solid #ffffff;
              box-sizing: content-box;
              position: relative;
              z-index: 1;
              &:nth-child(2n) {
                //transform: translate(80%, -80%);
                margin-top: -40%;
                margin-left: 40%;
                z-index: 2;
              }
            }
          }
        }
        .content{
          width: 48%;
          color: #ffffff;
          position: relative;
          z-index: 2;
          .text{
            font-size: 18px;
            line-height: 1.7;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
          }
        }
        @media (max-width: $mobilePoint) {
          h3{
            font-size: 36px;
          }
          h5{
            font-size: 28px;
          }

          flex-direction: column;
          .head{
            width: 100%;
          }
          .content{
            padding-top: 40px;
            width: 100%;
          }

        }
      }
    }
  }
  .owl-nav{
    .owl-prev, .owl-next{
      position: absolute;
      bottom: 10vw;
      left: 90%;
      span{
        display: none;
      }
      &:hover{
        &:after{
          background: rgba(255, 255, 255, 0.3);
        }
      }
      &:after{
        display: inline-block;
        color: #ffffff;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 300 !important;
      }
    }
    .owl-prev{
      margin-left: -28px;
      &:after {
        content: '\f053';
      }
    }
    .owl-next{
      margin-left: 28px;
      &:after {
        content: '\f054';
      }
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
  .owl-dots{
    position: absolute;
    display: inline-block;
    bottom: 10vw;
    left: 50%;
    transform: translateX(-50%);
    .owl-dot{
      margin: 0 10px;
      span{
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ffffff;
      }
      &.active{
        span{
          background: $colorSecondary;
        }
      }
    }
  }
}

.certs{
  padding: 50px 0;
  .certs-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .certs-content{
      width: 48%;
      h3{
        font-weight: 900;
        color: #000;
        font-size: 58px;
      }
      .text{
        line-height: 1.5;
        font-size: 20px;
        font-weight: 300;
        color: #000000;
      }
    }
    .certs-items{
      width: 48%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      .item{
        float: left;
        width: 22%;
        margin-bottom: 15px;
        margin-left: 15px;
        border: 3px solid $colorSecondaryLight;
        img{
          width: 100%;
        }
      }
    }
    @media (max-width: $mobilePoint) {
      flex-direction: column;
      .certs-content {
        width: 100%;
        h3 {
          font-size: 36px;
        }
      }
      .certs-items {
        width: 100%;
      }
    }
  }
}

.upbtn {
  opacity: 0.8;
  z-index: 9999;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: -80px;
  left: 20px;
  cursor: pointer;
  border:3px solid #BFE2FF;
  transition: all .7s ease-in-out;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $colorPrimary;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ0NC44MTkgNDQ0LjgxOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQ0LjgxOSA0NDQuODE5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQzMy45NjgsMjc4LjY1N0wyNDguMzg3LDkyLjc5Yy03LjQxOS03LjA0NC0xNi4wOC0xMC41NjYtMjUuOTc3LTEwLjU2NmMtMTAuMDg4LDAtMTguNjUyLDMuNTIxLTI1LjY5NywxMC41NjYgICBMMTAuODQ4LDI3OC42NTdDMy42MTUsMjg1Ljg4NywwLDI5NC41NDksMCwzMDQuNjM3YzAsMTAuMjgsMy42MTksMTguODQzLDEwLjg0OCwyNS42OTNsMjEuNDExLDIxLjQxMyAgIGM2Ljg1NCw3LjIzLDE1LjQyLDEwLjg1MiwyNS42OTcsMTAuODUyYzEwLjI3OCwwLDE4Ljg0Mi0zLjYyMSwyNS42OTctMTAuODUyTDIyMi40MSwyMTMuMjcxTDM2MS4xNjgsMzUxLjc0ICAgYzYuODQ4LDcuMjI4LDE1LjQxMywxMC44NTIsMjUuNywxMC44NTJjMTAuMDgyLDAsMTguNzQ3LTMuNjI0LDI1Ljk3NS0xMC44NTJsMjEuNDA5LTIxLjQxMiAgIGM3LjA0My03LjA0MywxMC41NjctMTUuNjA4LDEwLjU2Ny0yNS42OTNDNDQ0LjgxOSwyOTQuNTQ1LDQ0MS4yMDUsMjg1Ljg4NCw0MzMuOTY4LDI3OC42NTd6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  @media (max-width: 992px) {
    display:none
  }
}
.upbtn:hover {
  opacity: 1;
}


.main-order{
  background: $colorPrimaryDark;
  color: #ffffff;
  padding: 15px 0;
  .main-order-wrapper{
    padding: 30px 0;
    border: dashed rgba(255, 255, 255, 0.4);
    border-width: 1px 0 1px 0;
  }
  .main-order-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @media (max-width: $mobilePoint) {
      flex-direction: column;
    }
  }
  .main-order-col{
    width: 48%;
    @media (max-width: $mobilePoint) {
      width: 100%;
      padding: 15px 0;
    }
  }
  .text{
    color: rgba(255, 255, 255, 0.6);
    .h{
      color: #ffffff;
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 5px;
    }
  }
  .button{
    text-align: center;
    a{
      display: inline-flex;
      position: relative;
      overflow: hidden;
      background: $colorSecondary;
      color: #000000;
      height: 60px;
      line-height: 60px;
      border-radius: 6px;
      padding: 0 30px;
      text-decoration: none;
      font-size: 1.2vw;
      transition: box-shadow 0.3s, transform 0.3s;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(-45deg, transparent, rgba(255, 255, 255, 0.8), transparent);
        transition: left 0.7s;
      }
      &:hover{
        transform: translateY(-4px);
        box-shadow: 0 4px 12px rgba(0,0,0,0.2), 0 16px 20px rgba(0,0,0,0.2);
        //color: #FFF;
        &:before{
          left: 100%;
        }
      }
    }
    @media (max-width: $mobilePoint) {
      a{
        display: block;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.main-about{
  padding: 50px 0;
  h2{
    color: #000;
    font-weight: 900;
    font-size: 50px;
    margin-bottom: 30px;
    @media (max-width: 992px) {
      font-size: 36px;
    }
  }
  h3{
    color: #000;
    font-weight: 900;
    font-size: 32px;
    margin-bottom: 30px;
  }
  .text{
    font-weight: 400;
    color: #444;
    padding-right: 30px;
  }
}

.latest-news{
  padding-left: 20px;
  border-left: 1px solid $colorSecondary;
  .item{
    position: relative;
    margin-bottom: 40px;
    &:before{
      content: '';
      position: absolute;
      left: -28px;
      top: 3px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #000;
      border: 3px solid #ffffff;
    }
    .date{
      font-weight: 500;
      color: #000;
      margin-bottom: 0px;
    }
    .title{
      margin-bottom: 5px;
      font-weight: 500;
    }
    .description{
      font-weight: 300;
      color: #000;
    }
  }
}


$footerColor : #555;

.footer{
  color: $footerColor;
  background: #f0f0f0;
  a{
    color: $footerColor;
    text-decoration: none;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    &:hover{
      border-color: #000;
    }
  }
  .copy{
    border-top: 1px solid rgba(0,0,0, 0.2);
    padding: 15px 0;
    font-weight: 300;
  }
  .footer-content{
    padding: 30px 0;
  }
  .logo,
  .contacts,
  .menu{
    margin-bottom: 20px;
  }
  .logo{
    img{
      max-width: 100%;
      width: 240px;
    }
  }
  .h {
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
  }
  .contacts {
    .contact{
      margin: 5px 0;
    }
    .social{
      margin-top: 20px;
      a{
        margin-right: 10px;
        font-size: 32px;
        color: $footerColor;
        border: 0;
      }
    }
  }
  .menu{
    ul{
      padding: 0 0 0 20px;
      margin: 0;
      color: $footerColor;
      li{
        padding: 0;
        margin: 5px 0;
        a{
          color: $footerColor;
        }
      }
    }
  }
  .btn-footer{
    border: 1px solid rgba(0,0,0, 0.2);
    padding: 8px 20px;
    border-radius: 0;
    &:hover{
      color: #000000;
      background: rgba(255,255,255, 0.5);;
    }
  }
}


.mobile-menu {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  height: 57px;
  background: $colorPrimaryDark;
  //border-top: 1px solid #dddddd;
  justify-content: space-around;
  z-index: 9;
  .item{
    width: 100%;
    text-align: center;
    position: relative;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 100%;
      height: 100%;
      line-height: 57px;
      font-size: 28px;
      color: rgba(255, 255, 255, 0.6);
      .count {
        position: absolute;
        top: 9px;
        left: 50%;
        margin-left: 4px;
        display: inline-block;
        padding: 0 2px;
        background: var(--colorSecondary);
        color: #000;
        font-size: 11px;
        font-weight: bold;
        border-radius: 8px;
        line-height: 16px;
        min-width: 16px;
        text-align: center;
      }
      .mio{
        font-weight: 400;
        font-size: 36px;
      }
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
}

.inside-body{
  .dir-pic{
    display: block;
    position: relative;
    z-index: 3;

    &:before{
      content: "";
      padding-top: 10%;
      display: block;
    }
    .dir-pic-overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      background-image: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0.6) 100%);
      opacity: 0.8;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      z-index: 5;
    }

    .pic{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      z-index: 4;
    }
    .title{
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -24px;
      height: 48px;
      line-height: 48px;
      white-space: nowrap;
      width: 100%;
      padding: 0;
      color: #ffffff;
      text-shadow: 1px 1px 3px #000000;
      text-align: center;
      font-size: 55px;
      font-weight: bold;
      z-index: 6;
      @media (max-width: 1200px) {
        font-size: 22px;
      }
    }

  }

}

.technology-list{
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 18px;
    li{
      list-style: none;
      padding: 0;
      margin: 0;
      a{
        display: block;
        padding: 15px 0;
      }
    }
  }
  .pic{
    img{
      max-width: 100%;
    }
  }
}

.page-wrapper{
  margin-bottom: 30px;
}

.all-catalog{
  padding: 0;
  margin: 0;
  & > li{
    & > a {
      font-size: 18px;
      font-weight: 900;
      color: #000000;
    }
    margin: 15px 0;
    list-style: none;
    padding: 0;
    & > ul > li{
      margin: 10px 0;
    }
  }
  .pic {
    text-align: center;
  }
  .pic img{
    max-width: 100%;
  }
  h2 a{
    text-decoration: none;
  }
  .text{
    text-align: justify;
  }
  .row{
    margin-bottom: 70px;
  }
}


.catalog-list {
  .catalog-item{
    padding: 0 15px;
    float: left;
    width: 25%;
    @media (max-width: 1500px) {
      width: 33.333333%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
  }
  &.view-list{
    .catalog-item{
      width: 100%;
      margin: 0;
      .product-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        border-top: 1px dashed rgba(0,0,0,0.1);
        .name{
          height: auto;
          margin: 0 15px;
          flex-grow: 1;
        }
        .prices{
          order: 1;
          .price-r{
            font-size: 16px;
          }
          text-align: right;
        }
        .img{
          width: 70px;
          min-width: 50px;
        }
      }
      &:hover{
        background: rgba(0,0,0,0.05);
      }
    }
  }
}

.catalog-panel{
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding: 15px 0;
  .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus{
    outline: none;
  }
}

.catalog-groups{
  list-style: none;
  padding: 0;
  margin: 0;

  .item{
    text-align: center;
    display: block;
    margin-bottom: 30px;
    .name{
      font-weight: bold;
      display: block;
      color: #000000;
      height: 96px;
      overflow: hidden;
    }
    .pic{
      border-radius: 6px;
      display: block;
      background: url("./img/tovar_fon2.jpg") center;
      border: 1px solid rgba(31, 117, 190, 0.3);
    }
    &:hover .pic{
      background: url("./img/tovar_fon3.jpg") center;
      border: 1px solid rgba(31, 117, 190, 1);
    }
  }

}



.product-card{
  .img{
    border-radius: 6px;
    background: url("./img/tovar_fon2.jpg") center;
    border: 1px solid rgba(31, 117, 190, 0.3);
    &:hover{
      background: url("./img/tovar_fon3.jpg") center;
      border: 1px solid #1f75be;

    }
  }
  .img img{
    width: 100%;
  }
  .prices {
    margin: 10px 0;
    color: #000000;
    .price-r{
      font-weight: 900;
      font-size: 20px;
    }
    .price-0{
      font-size: 16px;
    }
  }
  .name {
    margin: 10px 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 1;
    height: 60px;
  }
  .rating {
    margin: 10px 0;
    img {
      width: auto;
      display: inline-block;
    }
    .comments{
      margin-left: 5px;
      a {
        color: #666666;
        text-decoration: none;
      }
    }
  }

}

.catalog-left-menu{
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid rgba(0,0,0,0.1);
  &>li{
    &>a {
      display: block;
      color: #000000;
      font-weight: bold;
      padding: 10px 20px;
      border-top: 1px solid rgba(0,0,0,0.1);
      text-decoration: none;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        a {
          display: block;
          padding: 10px 20px;
          border-top: 1px solid rgba(0,0,0,0.1);
          text-decoration: none;
        }
        &.active{
          a {
            background-color: $colorPrimary;
            color: #fff;
          }
        }
      }
    }
  }
}

.sitemap{
  &>ul{
    list-style: none;
    &>li{
      margin: 20px 0;
      &>a{
        font-size: 20px;
      }
      ul {
        li {
          margin: 10px 0;
        }
      }
    }
  }
}


.product-content{
  table{
    width: 100%!important;
    border-collapse: collapse;
    td{
      padding: 10px;
      margin: 0;
      border-bottom: 1px solid #f0f0f0;
      width: auto!important;
    }
  }
}

.product-price{
  margin-top: 30px;
  .num{
    color: #000000;
    font-size: 30px;
    font-weight: 900;
  }
}

.product-feedback{
  margin-top: 30px;
}

.db-content {
  line-height: 1.7;
  h1, h2, h3, h4{
    color: #000000;
    line-height: 1.4;
    margin: 1.2rem 0 1rem 0;
    font-weight: 900;
  }
  h1 {
    font-size: 36px;
    margin-top: 0;
    @media (max-width: 760px) {
      font-size: 26px;
    }
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 30px;
  }
}

.product-description{
  margin-top: 50px;
  line-height: 2;
  color: #888888;
}

.product-content{
  h3{
    margin: 40px 0 20px 0;
    font-size: 22px;
    font-weight: 900;
    color: #000000;
  }
  line-height: 1.7;
}

.product-alert{
  line-height: 2;
}

.alert-order{
  text-align: center;
  .h{
    font-size: 28px;
    color: #000000;
  }
  .phone{
    color: #000;
    a{
      color: #000;
      font-weight: bold;
      text-decoration: none;
    }
  }
  .message{
    margin-top: 15px;
  }
  .buttons{
    margin-top: 15px;
  }
  padding: 2rem 1rem;
  margin-top: 30px;
}


.news .item{
  margin: 40px 0;
  display: flex;
  .pic{
    width: 150px;
    min-width: 150px;
    margin-right: 20px;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .cont{
    flex-grow: 1;
  }
  .title{
    font-size: 18px;
    font-weight: 900;
  }
  .date{
    color: #999999;
  }
  .anonce {
    line-height: 1.8;
  }
}

.pagination{
  display: block;
  clear: left;
  width: 100%;
  padding: 0 15px;
  margin: 20px auto;
  text-align: center;

  &>li{
    display: inline-block;
  }
  &>li>a,
  &>li>span{
    display: inline-block;
    float: none;
    border: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%!important;
    color: #000000;
  }
  &>li>a:hover,
  &>li>span:hover{
    color: #000000;
  }

  &>.active>a,
  &>.active>a:focus,
  &>.active>a:hover,
  &>.active>span,
  &>.active>span:focus,
  &>.active>span:hover {
    background-color: $colorSecondary;
    border-color: $colorSecondary;
  }
}

.usage-list{
  .item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    background-size: cover;
    margin-bottom: 30px;
    text-decoration: none;
    border-radius: 6px;
    .title{
      width: 100%;
      background: transparentize($colorSecondary, 0.2);
      color: #000000;
      font-weight: 900;
      text-align: center;
      display: block;
      padding: 0.5rem 1rem;
      font-size: 1.5rem;
      transition: 0.2s;
    }
    &:hover{
      .title{
        background: $colorPrimary;
        color: #ffffff;
      }
    }
  }
}

.usage-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-pic,
  &-text{
    margin-bottom: 1rem;
  }
  &-pic{
    width: 20%;
    img{
      width: 100%;
      border-radius: 50%;
    }
  }
  &-text{
    width: 75%;
  }

  @media (max-width: 780px) {
    flex-wrap: wrap;
    &-pic,
    &-text{
      width: 100%;
    }
    &-pic{
      order: 1;
    }
  }
}