$primary: #1E73BE;
$info: #66A1D5;
$warning: #ffc107;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1500px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1500px
);

$grid-gutter-width: 2rem;

@import "../../node_modules/bootstrap/scss/bootstrap";

.form-group,
.form-floating{
  margin-bottom: 20px;
  &.has-error{
    .help-block{
      color: red;
    }
  }
}