//@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

$black: #000;
$white: #fff;
$grey: #eaeaea;

$primary-color: #07457C;
$primary-color-dark: darken( $primary-color, 10% );
$primary-color-tint-5: lighten( $primary-color, 10% );
$primary-color-tint-4: lighten( $primary-color, 20% );
$primary-color-tint-3: lighten( $primary-color, 30% );
$primary-color-tint-2: lighten( $primary-color, 60% );
$primary-color-tint-1: lighten( $primary-color, 70% );

$link-color: $black;


$sbm-width: 95%;
$sbm-padding: 0.875em 1em;
$sbm-border-color: $primary-color-tint-1;
$sbm-background-color: $white;
$sbm-icon-font: bootstrap-icons !important;
$sbm-icon-arrow-right: '\F285';
$sbm-icon-arrow-left: '\F284';

$sbm-level2-border-color: $primary-color-tint-2;
$sbm-level2-background-color: $primary-color-tint-1;

$sbm-level3-border-color: $primary-color-tint-3;
$sbm-level3-background-color: $primary-color-tint-2;
$sbm-level4-border-color: $primary-color-tint-4;
$sbm-level4-background-color: $primary-color-tint-3;
$sbm-level5-border-color: $primary-color-tint-5;
$sbm-level5-background-color: $primary-color-tint-4;


.sbm {

  .count{
    display: none;
  }
//  margin-top: 50px;
  transform: translateX(100%);
  a{
    text-decoration: none;
  }
  ul, li{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: $sbm-width;
  height: 100vh;
  background-color: $sbm-background-color;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: 0.45s;
}
.sbm-is-toggled {
  overflow: hidden;
  .sbm {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
  }
  &::after {
    opacity: 1;
    visibility: visible;
  }
}

// ---------------------------------------
// important stuff starts here

.sbm-upper{
  &>ul{
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.sbm {

  &-items {
    flex: 0 0 100%;
    padding-bottom: 60px!important;
    //overflow-y: auto;
    //overflow-x: hidden;
    height: 100%;
  }

  &-item {
    &:not(:last-child) {
      border-bottom: solid 1px $sbm-border-color;
    }
  }

  &-link {
    display: block;
    padding: $sbm-padding;
    background-color: $sbm-background-color;
    color: $link-color;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 400;
  }

  .sbm-back-link {
    display: flex;
    align-items: center;
    background-color: $primary-color !important;
    color: $white;

    &::before {
      content: $sbm-icon-arrow-left;
      margin-right: 0.5em;
      font-family: $sbm-icon-font;
    }
  }


  &-expand {
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      //height: 100%;
      transform: translateX(100%);
      background-color: $sbm-level2-background-color;
      transition: 0.3s;
      visibility: hidden;

      .sbm-item {
        &:not(:last-child) {
          border-bottom: solid 1px $sbm-level2-border-color;
        }
      }

      .sbm-link {
        background-color: $sbm-level2-background-color;
      }

    }

    &-link {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after {
        content: $sbm-icon-arrow-right;
        font-family: $sbm-icon-font;

        display: inline-block;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: -.125em;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for IE. */
        font-feature-settings: 'liga';
      }
    }

    &.active {
      > .sbm-expand-content {
        transform: translateX(0);
        visibility: visible;
      }
    }

    // quick example for other level colours
    .sbm-expand-content {
      background-color: $sbm-level2-background-color;
      .sbm-link {
        background-color: $sbm-level2-background-color;
      }
      .sbm-item {
        border-color: $sbm-level2-border-color;
      }
      .sbm-expand-content {
        background-color: $sbm-level3-background-color;
        .sbm-link {
          background-color: $sbm-level3-background-color;
        }
        .sbm-item {
          border-color: $sbm-level3-border-color;
        }
        .sbm-expand-content {
          background-color: $sbm-level4-background-color;
          .sbm-link {
            background-color: $sbm-level4-background-color;
          }
          .sbm-item {
            border-color: $sbm-level4-border-color;
          }
          .sbm-expand-content {
            background-color: $sbm-level5-background-color;
            .sbm-link {
              background-color: $sbm-level5-background-color;
            }
            .sbm-item {
              border-color: $sbm-level5-border-color;
            }
          }
        }
      }
    }
  }
}